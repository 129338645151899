import React from "react"
import { Link } from "gatsby"
import { Col, Card, Nav } from "react-bootstrap"
import Image from "./image"

import "../componentsStyles/servizio.css"

const FAQ = props => (
  <Nav.Item>
    <Nav.Link className="p-2 font-green nav-test" eventKey={props.eventKey}>
      {props.text}
    </Nav.Link>
  </Nav.Item>
)

export default FAQ
