import React from "react"

import { Container, Row, Col } from "react-bootstrap"
import layoutStyles from "../componentsStyles/layout.module.scss"
import "../componentsStyles/bannerClienti.css"
import CountUp from "react-countup"

const BannerOnline = props => (
  <Container className="mt-5" fluid="true">
    <Row>
    
      <Col className="p-0 fixed-online" md={5}>
        <img
          className="img-med"
          src="../nuovi-incentivi/nonsoloenergia-al-tuo-servizio.jpg"
          alt="NonSoloEnergia è al tuo servizio"
        />
      </Col>
      <Col
        className={`${layoutStyles.darkgreyColorBackground} fixed-online p-0`}
        md={7}
      >
        <Row className="about-margini">
          <Col className="text-justify text-center about-progetti">
            <h4>
              NonSoloEnergia è{" "}
              <span className={layoutStyles.lightGreenColor}>online</span>!
            </h4>
            <p>
              NSE srl è un partner affidabile a cui rivolgersi per utilizzare
              energia pulita in casa, investire nel solare con la propria
              azienda, efficientare i consumi energetici e ridurre i costi in
              bolletta: scopri l’ampia offerta di servizi di eccellenza, per un
              futuro migliore possibile già da oggi.
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  </Container>
)

export default BannerOnline
