import React from "react"
import Layout from "../components/layout"

import Sezione from "../components/sezione"
import SEO from "../components/seo"
import CardPagina from "../components/cardPagina"
import BanneronePage from "../components/banneronePage"
import TitoloPagina from "../components/titoloPagina"
import BottoneBianco from "../components/bottoneBianco"
import BanneroneIncentivi from "../components/banneroneIncentivi"
import { Link } from "gatsby"
import BannerOnline from "../components/bannerOnline"
import FAQ from "../components/faq"

import {
  Container,
  Row,
  Col,
  Card,
  Badge,
  Tab,
  ListGroup,
  Tabs,
  Nav,
} from "react-bootstrap"

const NuoviIncentivi = () => (
  <Layout>
    <SEO
      title="Nuovi Incentivi Fototovoltaico Aziendale Decreto FER 2019 - VI Conto Energia"
      description="A 5 anni dalla fine del Conto Energia, si possono ottenere super vantaggi per gli impianti fotovoltaici e in caso di smaltimento eternit o amianto"
      location="nuovi-incentivi-fotovoltaico-2019/"
    />
    <BanneroneIncentivi title="Nuovi Incentivi Fotovoltaico Aziendale" />
    <Container className="mt-4 mb-4">
      <Sezione title="Cosa ci porta il Nuovo Decreto FER 2019" />

      <CardPagina variante="bordoLeft bordoRight">
        <h2 className="text-center">
          Super Vantaggi in caso di smaltimento eternit/amianto !
        </h2>
        <Row>
          <Col xs={12}>
            <img
              className="text-center rounded mt-1 mx-auto img-fluid"
              src="../nuovi-incentivi/risparmio-con-decreto-fer-2019.jpg"
              alt="Grande risparmio con il decreto fer 2019"
            />
          </Col>
        </Row>
        <div className="text-center" />
        <p className="text-center">
          <strong>
            <em>
              A 5 anni dalla fine del Conto Energia, anche per il fotovoltaico
              si riaprono le porte degli incentivi.
            </em>
          </strong>
        </p>
        <p className="text-center titolino"> DECRETO IN VIGORE </p>
        <p>
          Essendo un meccanismo a registri, con quote contingentate per ogni
          sessione, questo decreto assomiglia un po’ al primo conto energia. La
          prima finestra per presentare la domanda si aprirà il 31 gennaio 2019
          per 30 giorni. Il GSE pubblicherà la graduatoria degli ammessi dopo 90
          giorni. Ci saranno anche ulteriori sessioni a maggio e a settembre con
          le stesse modalità.
        </p>
        <p className="text-center titolino"> DURABILITA'</p>

        <p>
          I vecchi repentini cambi normativi ci hanno insegnato che non è
          possibile fare previsioni. Quel che è certo e che viene fissato un
          tetto, quello del contatore fer elettriche non fotovoltaiche ad oggi a
          4,9 miliardi di €/anno. Il limite è 5,8. Una volta raggiunto il
          limite, finirà il meccanismo. Nella migliore delle ipotesi la legge
          prevede l’ultima asta il 31 maggio 2021.
        </p>
        <p className="text-center titolino"> CLASSIFICA PER GLI INCENTIVI</p>

        <p>
          per gli impianti sopra il mw quasi tutto si giocherà sul valore del
          ribasso offerto, per gli impianti sotto 1mw i criteri sono:
          <ul>
            <li>
              realizzazioni su discariche chiuse e sin (hanno 100 mw riservati
              alla prima asta)
            </li>
            <li>
              sostituzioni di amianti su edifici pubblici (hanno 45 mw riservati
              alla prima asta)
            </li>
            <li>
              impianti connessi in parallelo e con le colonnine di ricarica
              delle auto elettriche.
            </li>
          </ul>
        </p>
        <p className="text-center titolino"> INCENTIVI FOTOVOLTAICI</p>
        <p>
          L’energia immessa in rete viene incentivata con tariffa
          “omnicomprensiva” per 20 anni. Pertanto, nulla sarà dovuto per
          l’energia che si autoconsuma, nè si deve interpretare la tariffa come
          “premio” oltre al valore della energia. Le tariffe offerte sono
          disposte:
          <ul>
            <li>10,5cent/kwh fino a 100kw</li>
            <li>9 cent/kwh fino a 1mw</li>
            <li>7 cent/kwh oltre 1mw</li>
          </ul>
        </p>
        <p className="text-center titolino">SOSTITUZIONE AMIANTO</p>
        <p>
          Gli impianti fotovoltaici che appartengono al gruppo a-2, installati
          in sostituzione di coperture in amianto o eternit avranno diritto, in
          aggiunta agli incentivi su tutta l’energia prodotta a un premio pari a
          12€/mwh.
        </p>
        <p className="text-center titolino">COMPONENTI RIGENERATI</p>
        <p>
          Tariffa di riferimento ridotta 20% per impianti che accedono agli
          incentivi mediante procedura di registro e per la cui realizzazione
          sono impiegati componenti rigenerati.
        </p>
        <p className="text-center titolino">CAUZIONI</p>
        <p>
          Sono introdotte delle cauzioni anche per gli impianti sopra i 100kw e
          sotto 1mw, nella misura del 2% del costo previsto dal GSE pari a
          1000€/kw. Per esempio quindi per un 200 kw si verserà una cauzione
          provvisoria di 2000€ e definitiva di 4000€ che verranno poi svincolati
          quando l’impianto entra in funzione.
        </p>
        <p className="text-center titolino">VARIE</p>
        <ul>
          <li>
            I REQUISITI FINANZIARI PER IMPIANTI SOPRA 1MW SONO PIÙ STRINGENTI
          </li>
          <li>IMPIANTI UBICATI IN STATI ESTERI</li>
          <li>CONTRATTI STANDARD PER I PPA</li>
          <li>
            POSSIBILITÀ DI INTERROMPERE LA DECORRENZA DEI 20 ANNIPER LAVORI DI
            EFFICIENTAMENTTO
          </li>
          <li>MECCANISMO A DUE VIE.</li>
        </ul>

        <br />
      </CardPagina>
      <BannerOnline />

      <Sezione title="Cosa ci porta il Nuovo Decreto FER 2019" />

      <CardPagina>
        <h2 className="text-center ">
          <strong>
            <em>CONTO ENERGIA</em>
          </strong>
        </h2>
        <p>
          Per promuovere l’utilizzo di fonti rinnovabili per la creazione di
          energia, il 19 Settembre 2005 è entrata in vigore anche in Italia la
          possibilita’ di usufruire di incentivi per la costruzione di impianti
          fotovoltaici (pannelli solari che producono elettricità), che verranno
          erogati in “conto energia”, ovvero rivendendo tutta l’energia
          elettrica prodotta direttamente al gestore GSE (Gestore dei servizi
          elettrici) ad una tariffa incentivante.
        </p>
        <p className="titolino text-center">
          Come funziona il Conto Energia nella pratica?
        </p>
        <p>
          Di seguito viene rappresentato uno schema generale di un impianto
          fotovoltaico per chiarire meglio il funzionamento del conto energia.
        </p>
        <Row>
          <Col xs={12}>
            <img
              className="text-center rounded mt-1 mx-auto img-fluid"
              src="../nuovi-incentivi/come-funziona-conto-energia.png"
              alt="Come funziona il conto energia"
            />
          </Col>
        </Row>
        <p>
          L’energia prodotta dall’impianto fotovoltaico (A) viene convertita
          dall’inverter (B) e immessa nella rete locale a bassa tensione.
          <br /> <br />
          Il primo contatore (C1) posizionato dal gestore GSE accanto
          del’inverter, conteggia tutta l’energia prodotta dall’impianto
          fotovoltaico. L’energia prodotta viene ceduta al gestore locale D
          (solitamente ENEL) e conteggiata dal secondo contatore (C2) que rileva
          i KWH immessi alla rete. Si può immaginare la rete nazionale come una
          batteria di capacità infinita dove il produttore immete l’energia
          prodotta e quando necessita la preleva. Il vantaggio enorme di tale
          soluzione è che la rete nazionale non necessita di manutenzione e
          costi aggiuntivi dovuti alle perdite di carica o scarica della
          batteria e la sua sostituzione che avviene circa 10 anni. Il terzo
          contatore (C3), cioè il normale contattore che si ha normalmente in
          casa, conteggia il consumo energetico per i propri fabbisogni quando
          non vi è produzione di energia elettrica dall’impianto.
          <br /> <br />
          In sintesi il C2 ha la caratteristica di misurare l’energia immessa
          nella rete Nazionale, mentre il C3 quello di misurare il consumo. La
          soluzione tecnica che si sta adottando da un pò è che il contatore C2
          e C3 vengono condensati in uno unico contatore bidirezionale.
          <br /> <br />
          La tabella di seguito sintetizza il valore dell’incentivazione per il
          fotovoltaico, riconosciuta al variare della potenza secondo il nuovo
          Decreto FER 2019:
        </p>
        <Row>
          <Col xs={12}>
            <img
              className="text-center rounded mt-1 mx-auto img-fluid"
              src="../nuovi-incentivi/tabella-valore-incentivi.png"
              alt="Tabella del decreto FER 2019"
            />
          </Col>
        </Row>
        <p>
          I valori della Tabella sovrastante sono ridotti, a decorrere dal 1°
          Gennaio 2021, del 2% per le tipologie di impianti di cui al gruppo B e
          del 5% per le tipologie di impianti di cui al gruppo A. Il GSE
          pubblica sul proprio sito internet la tabella aggiornata.
        </p>
        <p>
          Le tariffe di cui allo schema precedente valgono per tutti quegli
          impianti que entreranno in funzione nel 2019, fatti salvi gli impianti
          di potenza fino a 20 kW che possono accedere alle detrazioni fiscali.
          Il decreto definisce altresì le tariffe che verrano applicate agli
          impianti che entreranno in produzione negli anni successivi al 2020
          fino al 2030 compresso; in pratica ogni anno succesivo al 2021 verrano
          applicate le tariffe dell’anno precedente ridotte del 2%.
        </p>
        <p>
          Le tariffe specificate ne decreto possono essere ulteriormente
          maggiorate (fino ad un massimo del 30%) qualora l’impianto sia
          abbinato ad interventi di efficientamento energetico; in particolare
          ad ogni riduzione del 10% del fabbisogno energetico di ogni unità
          abitativa (ottenuto attraverso interventi tesi alla riduzione delle
          perdite energetiche) farà seguito un aumento di pari entità della
          tariffa incentivante (fino, appunto, ad un massimo del 30%).
        </p>
        <p>
          Passa da 12 a 15 mesi il limite di tempo che può intercorrere tra la
          comunicazione di aggiudicazione dell’incentivo e l’entrata in
          esercizio dell’impianto senza che il bonus subisca una decurtazione.
        </p>
      </CardPagina>

      <Sezione title="ACCESSO AGLI INCENTIVI CONTO ENERGIA" />

      <CardPagina>
        <h2 className="text-center ">
          <strong>
            <em>ACCESSO AGLI INCENTIVI</em>
          </strong>
        </h2>
        <p>
          Il decreto chiarisce innanzitutto che l’accesso agli incentivi è
          alternativo al ritiro dell’energia, previsto dal D.lgs. 387/2003 e al
          meccanismo dello scambio sul posto. Per la partecipazione alle
          procedure di asta e di registro, per tutti gli impianti sono richiesti
          i titoli abilitativi alla costruzione e all’esercizio, il preventivo
          di connessione alla rete elettrica. Gli impianti fotovoltaici devono
          essere di nuova costruzione e realizzati con componenti di nuova
          costruzione.
        </p>
        <p>
          Devono inoltre rispettare le disposizioni dell’articolo 65 della Legge
          27/2012 sul divieto di accesso agli incentivi per gli impianti situati
          in zona agricola. Le richieste di partecipazione alle procedure di
          accesso agli incentivi sono inviate al GSE, esclusivamente tramite il
          sito <a href="http://www.gse.it">http://www.gse.it</a> , secondo
          modelli approntati e resi noti dallo stesso GSE, comprendenti la
          documentazione da fornire, strettamente funzionali alla verifica dei
          requisiti per la partecipazione alle procedure e dei criteri di
          priorità per l’accesso agli incentivi. Secondo quanto previsto
          dall’art 4.5 della Delibera AEEG n. 90/07, i seguenti documenti:
        </p>
        <p>
          <ul>
            <li>la richiesta dell’incentivo (All. A1/A1p)</li>
            <li>la scheda tecnica finale dell’impianto (All. A2/A2p)</li>
            <li>
              {" "}
              la dichiarazione sostitutiva di atto di notorietà (All. A4/A4P)
            </li>
            <li>
              la richiesta di premio per uso efficiente dell’energia (opzionale
              è All. A3a/A3b)
            </li>
          </ul>
        </p>
        <p className="scarica text-center">
          <strong>
            <a href="">SCARICA LA DELIBERA AEEG N.90 QUI!</a>
          </strong>
        </p>
        <p>
          Al fine di evitare gli errori più frequenti riscontrati, si raccomanda
          di seguire le seguenti indicazioni:
        </p>
        <p>
          <ul>
            <li>
              la richiesta di tariffa incentivante, la scheda tecnica finale
              d’impianto, la dichiarazione sostitutiva di atto di notorietà e
              l’eventuale richiesta del premio abbinato al risparmio energetico
              devono essere presentate su modelli stampati direttamente dal
              portale del GSE e firmate in originale;
            </li>
            <li>
              la dichiarazione sostitutiva di atto di notorietà deve essere
              autenticata da un notaio, cancelliere, segretario comunale, dal
              dipendente addetto a ricevere la documentazione o altro dipendente
              incaricato dal Sindaco.
            </li>
            <li>
              la documentazione finale di progetto deve contenere almeno una
              relazione generale, schemi di sistema e planimetrici
              dell’impianto, elaborati grafici di dettaglio che consentano di
              classificare l’impianto in una delle tipologie descritte alla’art.
              2 comma 1 lettere b1), b2), b3), e con riferimento per le medesime
              lettere b2), b3) a quelle specifiche di cui agli allegati 2 e 3
              del Decreto Ministeriale del 19 Febbraio 2007;
            </li>
            <li>
              il certificato di collaudo, da presentarsi in originale, deve
              attestare i risultati delle prestazioni dell’impianto. Tale
              obbligo è estesso a tutti gli impianti, non solo a quelli con
              potenza superiore a 50KWp, come prescritto dai precedenti decreti
              ministeriale.
            </li>
          </ul>
        </p>
      </CardPagina>
      <Sezione title="FAQ IMPIANTI FOTOVOLTAICI">
        <CardPagina variante="bordoLeft BordoRight">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={12}>
                <Nav variant="pills" className="flex-column ">
                  <FAQ
                    eventKey="1"
                    text="Posso installare con il conto energia l’impianto
                      fotovoltaico sul mio terreno?"
                  />
                  <Tab.Content>
                    {" "}
                    <Tab.Pane eventKey="1">
                      <p className="m-4">
                        Sì è possibile, fino ad oggi per accedere ai contributi
                        statali non era possibile, con il conto energia ora si
                        possono sfruttare anche ampi spazi di terreni per
                        installare gli impianti fotovoltaici.
                      </p>
                    </Tab.Pane>
                  </Tab.Content>
                  <FAQ
                    eventKey="2"
                    text="L’impianto fotovoltaico funziona anche se è nuvoloso?"
                  />
                  <Tab.Content>
                    {" "}
                    <Tab.Pane eventKey="2">
                      <p className="m-4">
                        Certo. L’impianto fotovoltaico funziona al meglio in
                        presenza di iraggiamento solare diretto (cielo sereno,
                        moduli orientati a sud, temperatura di 25°C) , ma un
                        minimo di energia elettrica è prodotta anche in caso di
                        cielo nuvoloso, sfrutando la radiazione solare difusa.
                      </p>
                    </Tab.Pane>
                  </Tab.Content>
                  <FAQ
                    eventKey="3"
                    text="Cosa succede dopo 20 anni con il Conto Energia?"
                  />
                  <Tab.Content>
                    {" "}
                    <Tab.Pane eventKey="3">
                      <p className="m-4">
                        Dopo aver usufruito per 20 anni dell’incentivo statale,
                        l’impianto fotovoltaico funzionerà in net metering, ciò
                        è ci sarà uno scambio alla pari con il gestore
                        elettrico, come è avvenuto fino ad adesso per gli
                        impianti che hanno usufruito di contributi statali.
                      </p>
                    </Tab.Pane>
                  </Tab.Content>
                  <FAQ eventKey="4" text="Posso vendere energia a ENEL?" />{" "}
                  <Tab.Content>
                    {" "}
                    <Tab.Pane eventKey="4">
                      <p className="m-4">
                        Il 5 Agosto 2005 è stato pubblicato sulla gazzetta
                        ufficiale n. 181 il programma Conto Energia, con questo
                        provvedimento fu possibile vendere l’energia elettrica
                        da impianti fotovoltaici direttamente al gestore GRTN.
                        Ora, con il nuovo Decreto, è ancora più monetizzato.
                      </p>
                    </Tab.Pane>
                  </Tab.Content>
                  <FAQ
                    eventKey="5"
                    text="Quanto può costare un impianto fotovoltaico?"
                  />{" "}
                  <Tab.Content>
                    {" "}
                    <Tab.Pane eventKey="5">
                      <p className="m-4">
                        Il costo dei pannelli si aggira indicativamente intorno
                        ai 4.200-4700 euro al kWp, a cui vanno aggiunti i costi
                        degli inverter, di progettazione e di installazione. Se
                        desiderate simulare il ritorno dell’investimento del
                        vostro impianto potete verificarlo qui:
                        <br />
                        <br />
                        Il Conto Energia permette incentivi che agevolano
                        l’investimento iniziale. Inoltre, sarebbe un’opzione
                        ecologica per un futuro sostenibile. Pensa due volte a
                        quanto è importante l’ambiente.
                      </p>
                    </Tab.Pane>
                  </Tab.Content>
                  <FAQ
                    eventKey="6"
                    text="Come posso accedere all’incentivo del Conto Energia?"
                  />{" "}
                  <Tab.Content>
                    {" "}
                    <Tab.Pane eventKey="6">
                      <p className="m-4">
                        Entro sessanta giorni dall’entrata in funzione
                        dell’impianto occorre inviare al gestore della rete:
                        <ul>
                          <li>
                            Documentazione di conformità dell’impianto alle
                            norme CEI;
                          </li>
                          <li>Scheda tecnica dell’impianto;</li>
                          <li>Certificazione di collaudo;</li>
                          <li>
                            Dichiarazione sostitutiva di atto di notorietà
                            autenticata;
                          </li>
                        </ul>
                      </p>
                    </Tab.Pane>
                  </Tab.Content>
                  <FAQ
                    eventKey="7"
                    text="Posso installare da solo un impianto fotovoltaico?"
                  />{" "}
                  <Tab.Content>
                    {" "}
                    <Tab.Pane eventKey="7">
                      <p className="m-4">
                        L’installazione richiede conoscenze specifiche sia in
                        termini di tecnologia che di sicurezza, normative e
                        certificazioni, per cui le suggeriamo di contattare NSE
                        srl per avere una consulenza adeguata.
                      </p>
                    </Tab.Pane>
                  </Tab.Content>
                  <FAQ eventKey="8" text="Cos’è un fotovoltaico?" />
                  <Tab.Content>
                    {" "}
                    <Tab.Pane eventKey="8">
                      <p className="m-4">
                        Un modulo fotovoltaico è un dispositivo che permette di
                        convertire l’energia solare in energia elettrica
                        utilizzando la proprietà di alcuni materiali, come il
                        silicio, di produrre energia elettrica se irradiati
                        dalla luce solare.
                        <br />
                        <br />
                        Il termine stesso “fotovoltaico” racchiude in sè queste
                        caratteristiche, derivando da foto (= luce) e voltaico
                        (dall’inventore della batteria, Alessandro Volta).
                        Singoli moduli fotovoltaici sono generalmente assemblati
                        meccanicamente tramite una intelaiatura, che li protegge
                        anche dagli agenti atmosferici, dando vita ai pannelli
                        fotovoltaici. I pannelli fotovoltaici vengono installati
                        in modo da essere esposti direttamente ai raggi solari
                        (tetti di abitazione, terrazzi, cortili). La luce solare
                        viene trasformata in corrente continua, passata
                        attraverso dispositivi detti “balance of system” e
                        trasformata in corrente alternata tramite un sistema di
                        “inverter”. L’energia così creata può a questo punto
                        essere utilizzata per le utenze tradizionali o immessa
                        nella rete elettrica e misurata da uno speciale
                        contatore del gestore della rete elettrica, che la
                        acquisterà come credito da applicare sulla bolletta
                        (vedi Conto Energia).
                      </p>
                    </Tab.Pane>
                  </Tab.Content>
                  <FAQ
                    eventKey="9"
                    text="Che durata ha un impianto fotovoltaico?"
                  />
                  <Tab.Content>
                    {" "}
                    <Tab.Pane eventKey="9">
                      <p className="m-4">
                        I moduli fotovoltaici hanno una vita stimata che va dai
                        50 ai 100 anni circa, anche se è plausibile pensare a
                        dismissioni dopo 25/30 a causa dell’obsolescenza della
                        tecnologia e a possibili riduzioni di efficienza, mentre
                        per un impianto si può pensare a trenta e più anni senza
                        dover essere modificato o ristrutturato.
                        <br />
                        <br />I moduli per accedere ai contributi in conto
                        energia devono essere garantiti dal costruttore per
                        almeno 20 anni.
                      </p>
                    </Tab.Pane>
                  </Tab.Content>
                  <FAQ
                    eventKey="10"
                    text="Sono possibili anche installazioni per aziende, oltre che abitazioni?"
                  />{" "}
                  <Tab.Content>
                    {" "}
                    <Tab.Pane eventKey="10">
                      <p className="m-4">
                        Decisamente sì.
                        <br />
                        <br />I fattori da considerare sono gli stessi definiti
                        prima (requisiti in termini di potenza elettrica voluta,
                        superfici per l’installazione dell’impianto,
                        orientativamente delle superfici.) I contesti
                        industriali spesso agevolano queste installazioni, visto
                        che la disponibilità di superfici non ombreggiate è
                        spesso maggiore. Il Conto Energia potrebbe anche fornire
                        vantaggi anche maggiori dalla produzione di energia
                        elettrica e vendita del surplus all’ente erogante.
                      </p>
                    </Tab.Pane>
                  </Tab.Content>
                  <FAQ
                    eventKey="11"
                    text="Ci sono requisiti particolari per l’installazione di un impianto fotovoltaico?"
                  />{" "}
                  <Tab.Content>
                    {" "}
                    <Tab.Pane eventKey="11">
                      <p className="m-4">
                        Un impianto fotovoltaico può essere installato
                        praticamente ovunque senza problemi particolari. Gli
                        unici fattori da tenere in considerazione sono:
                        <ul>
                          <li>
                            Necessità dell’impianto in termini di potenza
                            elettrica
                          </li>
                          <li>
                            Disponibilità di superfici su cui installare i
                            pannelli senza ostacoli all’esposizione del sole
                            (tipo altri edifici o alberi); tipicamente la
                            soluzione più adatta è il tetto, ma si possono
                            considerare anche possibilita’ alternative quali
                            pareti, strutture comuni o terreni.
                          </li>
                          <li>
                            Dimensioni e orientamento di tali superfici: da est,
                            sud ed ovest vanno bene, anche se le prestazioni
                            sono leggermente differenti
                          </li>
                          <li>Spazi per installare l’inverter e i fili.</li>
                        </ul>
                        <br />
                        Durante la valutazione di fattibilità, gli esperti di
                        NSE srl lavoreranno direttamente con voi su questi
                        aspetti per valutare la soluzione migliore per le vostre
                        esigenze, fino ad arrivare all’installazione e
                        certificazione dell’impianto.
                      </p>
                    </Tab.Pane>
                  </Tab.Content>
                  <FAQ
                    eventKey="12"
                    text="Che tipo di impianti fotovoltaici esistono?"
                  />{" "}
                  <Tab.Content>
                    {" "}
                    <Tab.Pane eventKey="12">
                      <p className="m-4">
                        Esistono due grosse tipologie di impianti: autonomi (con
                        accumulo o stand alone) e connessi alla rete (o grid
                        connected). <br />
                        <br />I sistemi autonomi utilizzano l’energia prodotta
                        dall’esposizione solare durante il giorno per caricare
                        una batteria e rilasciare, poi, l’energia immagazzinata
                        durante la notte o quando il sole è coperto, per
                        alimentare oggetti di piccole e medie dimensioni
                        (strumenti di emergenza, lampioni stradali, ripetitori,
                        ecc.). I sistemi grid connected sono normalmente
                        utilizzati per fornire energia a una rete elettrica già
                        alimentata da generatori convenzionali e cedere all’ente
                        erogatore il surplus, utilizzando un apposito contatore
                        che registra i flussi di entrata e di uscita.
                        Caratteristica esclusiva di questi impianti è
                        l’inverter, che trasforma la corrente continua in
                        alternata.
                      </p>
                    </Tab.Pane>
                  </Tab.Content>
                  <FAQ
                    eventKey="13"
                    text="Quanto tempo richiede l’installazione?"
                  />
                  <Tab.Content>
                    {" "}
                    <Tab.Pane eventKey="13">
                      <p className="m-4">
                        Il tempo richiesto dipende alla complessità
                        dell’impianto e gli interventi richiesti. In fase di
                        preventivo verrano stimati anche i tempi di
                        installazione.
                      </p>
                    </Tab.Pane>
                  </Tab.Content>
                  <FAQ
                    eventKey="14"
                    text="Come funziona praticamente il Conto Energia?"
                  />{" "}
                  <Tab.Content>
                    {" "}
                    <Tab.Pane eventKey="14">
                      <p className="m-4">
                        Come già in altri paesi Europei, anche in Italia il
                        Governo ha attua il piano di incentivi per favorire la
                        realizzazione di impianti fotovoltaici domestici,
                        condominiali o industriali. Tale piano attuativo,
                        chiamato Conto Energia, a fronte di alcuni parametri da
                        rispettare, consente di scambiare o vendere alle società
                        elettriche l’energia prodotta dal proprio impianto
                        fotovoltaico. Grazie al Conto Energia, su un periodo di
                        medio-lunga durata, non solo non si paga per l’energia
                        elettrica consumata ma si riesce a fare dei guadagni.
                        L’impianto fotovoltaico diventa un vero e proprio
                        investimento industriale. NSE srl offre tutte le
                        informazioni che consentono di valutare le opportunità
                        che si aprono grazie all’installazione di impianti
                        fotovoltaici.
                      </p>
                    </Tab.Pane>
                  </Tab.Content>
                  <FAQ
                    eventKey="15"
                    text="Che tipo di agevolazioni fiscali esistono per il fotovoltaico?"
                  />{" "}
                  <Tab.Content>
                    {" "}
                    <Tab.Pane eventKey="15">
                      <p className="m-4">
                        Il principale incentivo nazionale è Conto Energia: per
                        tutti gli impianti di potenza compresa tra 1 e 1000KW,
                        incentivo della durata di 20 anni sull’energia prodotta
                        dall’impianto connesso alla rete elettrica (DM del 6
                        Febbraio 2006 che aggiorna il precedente DM del 28
                        Luglio, conto energia) A questi incentivi si possono
                        sommare alcune opzioni:
                        <ul>
                          <li>
                            Vendere l’energia prodotta al gestore della rete ad
                            un prezzo fissato (Delibera n.34/05 – Acquirente
                            Unico) o venderla sul mercato libero (Delibera
                            n.168/03);
                          </li>
                          <li>
                            per impianti di potenza non superiore ai 20 KW
                            connessi alla rete elettrica si può scegliere di
                            applicare lo scambio sul posto (scambio di energia
                            con la rete elettrica) tra l’energia elettrica
                            immessa in rete e quella prelevata dalla rete.
                          </li>
                          <li>aliquota IVA al 10%</li>
                          <li>
                            possibilità di integrare il Conto Energia con
                            incentivi in conto capitale che non superino il 20%
                            dell’investimento.
                          </li>
                        </ul>
                        <br />
                      </p>
                    </Tab.Pane>
                  </Tab.Content>
                  <FAQ
                    eventKey="16"
                    text="Quanto spazio occupa un impianto fotovoltaico?"
                  />{" "}
                  <Tab.Content>
                    {" "}
                    <Tab.Pane eventKey="16">
                      <p className="m-4">
                        Dipende principalmente dal tipo di pannelli
                        (monocristallino, policristallino, amorfo). Lo spazio
                        occupato dai pannelli fotovoltaici monocristallini
                        installati su tetto inclinato è pari a circa 8 m² per Kw
                        installato mentre per quanto riguarda i tetti piani è
                        pari a circa 12 m² per Kw.
                      </p>
                    </Tab.Pane>
                  </Tab.Content>
                  <FAQ
                    eventKey="17"
                    text="Quanto produce un impianto fotovoltaico?"
                  />
                  <Tab.Content>
                    {" "}
                    <Tab.Pane eventKey="17">
                      <p className="m-4">
                        La produzione di un impianto fotovoltaico è funzione
                        principalmente della latitudine di installazione
                        dell’impianto e ovviamente della potenza installata.
                      </p>
                    </Tab.Pane>
                  </Tab.Content>
                  <FAQ
                    eventKey="18"
                    text="Quanto produce un impianto fotovoltaico?"
                  />{" "}
                  <Tab.Content>
                    {" "}
                    <Tab.Pane eventKey="18">
                      <p className="m-4">
                        Nel caso di un impianto fotovoltaico che usufruisce di
                        un incentivazione in conto energia, tale grandezza è in
                        funzione della potenza installata, della tipologia di
                        impianto e dell’irraggiamento solare specifico della
                        zona in cui l’impianto è sito. Il ritorno di
                        investimento varia dai 4 ai 6 anni a seconda della
                        latitudine e delle dimensioni dell’impianto. A ciò va
                        inoltre aggiunto l’incentivo ulteriore determinato dalla
                        disciplina di “scambio sul posto”.
                      </p>
                    </Tab.Pane>
                  </Tab.Content>
                  <FAQ eventKey="19" text="Che tipo di tecnologie esistono?" />{" "}
                  <Tab.Content>
                    {" "}
                    <Tab.Pane eventKey="19">
                      <p className="m-4">
                        Tutte le tecnologie si basano sull’uso del silicio,
                        l’elemento più diffuso sulla crosta terrestre (28%) dopo
                        l’ossigeno. Le tecnologie più comuni sono 3:
                        <ul>
                          <li>
                            Silicio monocristallino, che utilizza silicio
                            purissimo, con atomi perfettamente allineati che
                            garantiscono la massima conducibilità
                          </li>
                          <li>
                            Silicio policristallino, con monocristalli di
                            silicio aggregati con forme e orientamenti diversi
                          </li>
                          <li>
                            Silicio amorfo o a film sottile, che non ha
                            struttura cristallina e i cui atomi vengono deposti
                            chimicamente in ordine casuale senza alcun
                            allineamento (struttura amorfa), utilizzando
                            quantità di silicio molto basse.
                          </li>
                        </ul>
                        <br />
                        Ogni tecnologia si differenzia per prestazioni,
                        rendimento, costi e durata. Le prestazioni dipendono da
                        caratteristiche quali l’irraggiamento, la posizione, la
                        temperatura di esercizio ecc. Il rendimento è la
                        percentuale di energia trasformata rispetto a quella
                        irraggiata sul modulo, e il passaggio è dal
                        monocristallino all’amorfo in ordine decrescente ovvero,
                        a parità di produzione di energia elettrica, un pannello
                        fotovoltaico amorfo occupera’ una superficie maggiore
                        rispetto ad un equivalente cristallino. In termini di
                        costi, lï¿½amorfo costa meno ma ha un tempo di vita di
                        10 anni mentre, un modulo policristallino ha garanzia di
                        vita per 25-30 anni
                        <br />
                        <br />
                        Esistono poi altre tecnologie più recenti (ad esempio
                        l’eterogiunzione e il silicio microsferico) ma non
                        ancora commercializzate al grande pubblico. In tutti i
                        casi, il silicio viene poi fuso in lingotti di 10/15 cm
                        di diametro e affettato in sezioni di 0,25-0,35 mm di
                        spessore. Due strati di silicio a differente potenziale
                        elettrico posti tra le due facce delle sezioni ricavate
                        dai lingotti costituiscono la cellula fotovoltaica. Le
                        celle fotovoltaiche collegate tra loro formano i moduli
                        fotovoltaici che come abbiamo visto sono in grado di
                        trasformare la luce solare in energia elettrica.
                      </p>
                    </Tab.Pane>
                  </Tab.Content>
                  <FAQ
                    eventKey="20"
                    text="Ci sono costi o interventi aggiuntivi nel tempo?"
                  />{" "}
                  <Tab.Content>
                    {" "}
                    <Tab.Pane eventKey="20">
                      <p className="m-4">
                        Una volta installato, l’impianto è practicamente
                        autosuffieciente.
                        <br /> Costi aggiuntivi sarebbero legati solo ad eventi
                        calamitosi o vandalici. Considerando che in fase di
                        progettazione i moduli dai noi utilizzati vengono
                        sottoposti a test di resistenza alla grandine (anche di
                        grosse dimensioni), installando pannelli certificati da
                        enti internazionali, il rischio di costi aggiuntivi è
                        praticamente nullo. Gli unici interventi aggiuntivi
                        possono essere legati ad eventi speciali (ad esempio
                        neve), che richiedono una pulizia manuale dei pannelli,
                        altrimenti autopulenti grazie all’esposizione a pioggia
                        e vento.
                      </p>
                    </Tab.Pane>
                  </Tab.Content>
                  <FAQ
                    eventKey="21"
                    text="Con il fotovoltaico sono immune da black-out?"
                  />{" "}
                  <Tab.Content>
                    {" "}
                    <Tab.Pane eventKey="21">
                      <p className="m-4">
                        NO. Per ragioni di sicurezza, in caso di black-out
                        manutentivo da parte dell’ENEL l’impianto non è
                        funzionante, per evitare rischi di sicurezza per gli
                        operai, che potrebbero essere fulminati dall’energia
                        generata dall’impianto fotovoltaico.
                      </p>
                    </Tab.Pane>
                  </Tab.Content>
                  <FAQ
                    eventKey="22"
                    text="L’installazione di un impianto fotovoltaico richiede modifiche al mio impianto elettrico esistente?"
                  />{" "}
                  <Tab.Content>
                    {" "}
                    <Tab.Pane eventKey="22">
                      <p className="m-4">
                        No, non si deve modificare l’impianto esistente.
                        L’impianto fotovoltaico ha una sua linea autonoma che
                        dev’essere collegata al quadro elettrico generale, e due
                        contatori installati dal gestore elettrico: uno in
                        entrata, per registrare l’energia elettrica prelevata
                        dalla rete nazionale, e uno in uscita, per registrare
                        l’energia elettrica immessa in rete dall’impianto
                        fotovoltaico (/oppure un contatore bidirezionale che
                        conteggia sia l’energia elettrica in entrata che quella
                        in uscita).
                      </p>
                    </Tab.Pane>
                  </Tab.Content>
                </Nav>
              </Col>
            </Row>
          </Tab.Container>
        </CardPagina>
      </Sezione>
      <CardPagina variante="white bordoTop mt-5">
        <Row>
          <Col xs={12} className="mt-3">
            <p className="titoloGrande text-center gridParity">
              RIMANI IN CONTATTO CON NOI
            </p>
            <p className="text-center">
              Con la diffusione di Internet a macchia d’olio e raggiungendo ogni
              parte della nostra vita quotidiana, sempre più traffico viene
              indirizzato ai siti Web in cerca di informazioni. Quindi… cosa
              stai aspettando?
            </p>
          </Col>
          <Col xs={12}>
            <BottoneBianco
              className="mt-3 mx-auto text-center variantePage"
              title="Richiedi il tuo preventivo"
              link="richiesta-informazioni"
            />
          </Col>
        </Row>
      </CardPagina>
    </Container>
  </Layout>
)

export default NuoviIncentivi
