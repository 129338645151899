import React from "react"
import { Carousel } from "react-bootstrap"
import Image from "./image"
import BottoneBianco from "./bottoneBianco"

import "../componentsStyles/bannerone.css"

const BanneroneIncentivi = () => (
  <Carousel indicators={false} controls={false}>
    <Carousel.Item>
      <Image
        className="w-100 fixedHeight"
        filename="fotovoltaico-carousel"
        alt="NonsoloEnergia SRL"
      />
      <Carousel.Caption className="text-center">
        <h1>Nuovi Incentivi Fotovoltaico Aziendale <br/> Decreto FER 2019 - VI CONTO ENERGIA </h1>
      </Carousel.Caption>
    </Carousel.Item>
  </Carousel>
)

export default BanneroneIncentivi
